@import url(https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
.balance_box {
    .css-mnn31 {
        color: #fff !important;
    }
    .MuiInputBase-formControl.css-1vv4lmi::before {
        border-bottom: 1px solid #fff !important;
    }

    .MuiInputBase-formControl.css-1vv4lmi::after {
        border-bottom: 1px solid #fff !important;
    }
}

.checkout .css-1vv4lmi {
    color: #fff;
}

input::-webkit-contacts-auto-fill-button {
    background-color: #fff !important;
    Color: #fff;
}

.css-1vv4lmi::before {
    border-bottom-color: #fff;
}

.bp3-overlay-backdrop {
    background: rgba(0, 0, 0, 0.53) !important;
}

/*.bp3-toast-container {*/
/*    margin: 0;*/
/*    position: fixed;*/
/*    top: 50% !important;*/
/*    -ms-transform: translateY(-50%) !important;*/
/*    transform: translateY(-50%) !important;*/
/*}*/

.bp3-dialog {
    margin: 0;
    position: fixed;
    top: 20% !important;
    padding: 10px !important;
    border-radius: 5px !important;
}

.justify-content-around {
    display: flex !important;
    justify-content: space-around !important;
}

.floating-cart {
    position: fixed;
    bottom: 80px;
    right: 53px;
    z-index: 10;
    transition: 0.5s;
}
.floating-cart i {
    background: #000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 1.3rem;
    color: #dfdfdf;
    border: 1px solid #fff;
}
.floating-cart:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.floating-cart:hover i{
    box-shadow: 0 10px 10px -6px #000;
}

.floating-order {
    position: fixed;
    bottom: 135px;
    right: 53px;
    z-index: 10;
    transition: 0.5s;
}
.floating-order i {
    background: #000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 1.3rem;
    color: #dfdfdf;
    border: 1px solid #fff;
}
.floating-order:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.floating-order:hover i{
    box-shadow: 0 10px 10px -6px #000;
}

.floating-home {
    position: fixed;
    bottom: 80px;
    right: 53px;
    z-index: 10;
    transition: 0.5s;
}
.floating-home i {
    background: #000;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    font-size: 1.3rem;
    color: #dfdfdf;
    border: 1px solid #fff;
}
.floating-home:hover {
    -webkit-transform: translateY(-5px);
            transform: translateY(-5px);
}
.floating-home:hover i{
    box-shadow: 0 10px 10px -6px #000;
}

* {
    font-family: 'Raleway', sans-serif;
}

p {
    font-family: 'Raleway', sans-serif;
    color: #727272;
    letter-spacing: 0;
    font-weight: 400;
}

img {max-width: 100%;height: auto;}
.thyro_banner img {width: 100%;height: auto;}

#scrollToTop button span img {-webkit-filter: grayscale(1) !important;filter: grayscale(1) !important;}

.h_50 {height: 40px !important;}

/* Main Color Change Start */
/* width */
::-webkit-scrollbar {
    width: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #000000 !important;
    border-radius: 0;
}
  
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #0a0a0a !important; 
}

.header-bar .bp3-navbar.bp3-blue {
    background-color: #000000;
    box-shadow: 0 5px 5px 0 rgb(0 0 0 / 62%);
    -webkit-box-shadow: 0 5px 5px 0 rgb(0 0 0 / 62%);
}
.header-bar .bp3-navbar input.bp3-input {
    background-color: #000000 !important;
    border: 2px solid #F7FAFF !important;
    border-radius: 5px !important;
}
.header-bar .bp3-navbar .bp3-input-group .bp3-button:focus {
    background-color: #000 !important;
    border-radius: 0 5px 5px 0 !important;
    border-top: 2px solid #F7FAFF !important;
    border-right: 2px solid #F7FAFF !important;
    border-bottom: 2px solid #F7FAFF !important;
}
.header-bar .bp3-navbar .bp3-input-group .bp3-button:hover {
    background-color: #000 !important;
    border-radius: 0 5px 5px 0 !important;
    border-top: 2px solid #F7FAFF !important;
    border-right: 2px solid #F7FAFF !important;
    border-bottom: 2px solid #F7FAFF !important;
}
.header-bar .bp3-navbar .bp3-input-group .bp3-button {
    background-color: #000 !important;
    border-radius: 0 5px 5px 0 !important;
    border-top: 2px solid #F7FAFF !important;
    border-right: 2px solid #F7FAFF !important;
    border-bottom: 2px solid #F7FAFF !important;
}
.footer .container--footer-primary, .footer .container--footer-secondry {
    background-color: #000 !important;
}
.footer .container--footer-primary, .footer .container--footer-secondry {
    background: #000 !important;
}
.copyright {
    background: #0a0a0a !important;
}
/* Main Color Change End */

.main_bg_color {background: #0a0a0a;}
.main_bg_color2 {
    background: #0a0a0a;
    background-color: #0a0a0a !important;
    color: #fff !important;
}
.main_color {color: #0a0a0a;}

/*GG Prepaid Products Start*/
.thyro_products_title {margin-bottom: 20px;}
.thyro_products_title h3{font-weight: bolder;color: #0a0a0a;font-size: 1.5rem;margin: 10px 0 0 0;}
.thyro_products_title h5 {
    margin: 10px 0 0 0;
}
.thyro_products_title h5 a {
    color: #b4b4b4;
    transition: 0.5s;
    font-size: 1rem;
    font-weight: 500;
}
.thyro_products_title h5 a:hover {opacity: 0.8;text-decoration: none;}

@media (max-width: 992px) {
    .thyro_products_title {margin-bottom: -20px;}
    .thyro_products_title h3{margin: 0 0 10px 0;}
    .thyro_products_title h5{margin: 0 0 20px 0;}
}

.width-100 {width: 100%;}
.width-70 {width: 70%;}


.mt-10 {
    margin-top: 10px !important;
}

.bp3-button.bp3-intent-primary.bp3-active, .bp3-button.bp3-intent-primary.bp3-active:hover, .bp3-button.bp3-intent-primary:active, .bp3-button.bp3-intent-primary:hover {
    background-color: #000000 !important;
    background: #000000 !important;
}

.bp3-button.bp3-intent-primary {
    background: #000000;
    border-color: #000000;
}

.bp3-button.bp3-intent-primary:hover {
    border-color: #000000 !important;
}

@media screen and (max-width: 5000px) and (min-width: 577px) {
    .ggvoucher .btn {
        margin-top: 0 !important;
        margin-left: 20px !important;     
    }
}

@media (max-width: 576px) {
    .Order_Detail ul li p:nth-child(2) {
        text-align: right;
    }
    .ggvoucher .btn {
        margin-top: 20px !important;
        margin-left: 0px !important;     
    }
    .fix-to-bottom .bp3-button {
        background: #0a0a0a !important;
        padding: 4px 8px !important;
        border-radius: 50px !important;
        font-size: 12px;
        background-color: #0a0a0a !important;
        min-height: 15px !important;
        line-height: 16px !important;
        box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.16) !important;
    }
    .floating-menu {
        border-radius: 100% !important;
        z-index: 9;
        right: 3%;
        position: fixed;
        padding: 12px 18px 8px 18px !important;
        bottom: 5%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        font-size: 10px !important;
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2) !important;
    }
}

/* Form Start */
.fieldset {
    padding: 0;
    margin: 0;
    border: 0;
    min-width: 0;
}

legend {
    display: block;
    width: 100%;
    padding: 0;
    margin-bottom: 20px;
    font-size: 21px;
    line-height: inherit;
    color: #333333;
    border: 0;
    border-bottom: 1px solid #e5e5e5;
}

label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: inherit;
}

input[type="search"] {
    box-sizing: border-box;
}

input[type="radio"],
input[type="checkbox"] {
    margin: 4px 0 0;
    margin-top: 1px \9;
    line-height: normal;
}

input[type="range"] {
    display: block;
    width: 100%;
}

select[multiple],
select[size] {
    height: auto;
}

input[type="radio"]:focus,
input[type="checkbox"]:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
}

output {
    display: block;
    padding-top: 7px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
}

.form-control {
    display: block;
    width: 100%;
    height: 40px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: none;
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
    border-color: #66afe9;
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
    color: #999;
    opacity: 1;
}

.form-control:-ms-input-placeholder {
    color: #999;
}

.form-control::-webkit-input-placeholder {
    color: #999;
}

.form-control::-ms-expand {
    border: 0;
    background-color: transparent;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
    background-color: #eeeeee;
    opacity: 1;
}

.form-control[disabled], fieldset[disabled] .form-control {
    cursor: not-allowed;
}

textarea.form-control {
    height: auto;
}

input[type="search"] {
    -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
    input[type="date"].form-control,
    input[type="time"].form-control,
    input[type="datetime-local"].form-control,
    input[type="month"].form-control {
        line-height: 34px;
    }

    input[type="date"].input-sm, .input-group-sm input[type="date"],
    input[type="time"].input-sm, .input-group-sm
    input[type="time"],
    input[type="datetime-local"].input-sm, .input-group-sm
    input[type="datetime-local"],
    input[type="month"].input-sm, .input-group-sm
    input[type="month"] {
        line-height: 30px;
    }

    input[type="date"].input-lg, .input-group-lg input[type="date"],
    input[type="time"].input-lg, .input-group-lg
    input[type="time"],
    input[type="datetime-local"].input-lg, .input-group-lg
    input[type="datetime-local"],
    input[type="month"].input-lg, .input-group-lg
    input[type="month"] {
        line-height: 46px;
    }
}

.form-group {
    margin-bottom: 0px;
}

.radio,
.checkbox {
    position: relative;
    display: block;
    margin-top: 10px;
    margin-bottom: 10px;
}

.radio label,
.checkbox label {
    min-height: 20px;
    padding-left: 20px;
    margin-bottom: 0;
    font-weight: normal;
    cursor: pointer;
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    position: absolute;
    margin-left: -20px;
    margin-top: 4px \9;
}

.radio + .radio,
.checkbox + .checkbox {
    margin-top: -5px;
}

.radio-inline,
.checkbox-inline {
    position: relative;
    display: inline-block;
    padding-left: 20px;
    margin-bottom: 0;
    vertical-align: middle;
    font-weight: normal;
    cursor: pointer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-top: 0;
    margin-left: 10px;
}

input[type="radio"][disabled], input[type="radio"].disabled, fieldset[disabled] input[type="radio"],
input[type="checkbox"][disabled],
input[type="checkbox"].disabled, fieldset[disabled]
input[type="checkbox"] {
    cursor: not-allowed;
}

.radio-inline.disabled, fieldset[disabled] .radio-inline,
.checkbox-inline.disabled, fieldset[disabled]
.checkbox-inline {
    cursor: not-allowed;
}

.radio.disabled label, fieldset[disabled] .radio label,
.checkbox.disabled label, fieldset[disabled]
.checkbox label {
    cursor: not-allowed;
}

.form-control-static {
    padding-top: 7px;
    padding-bottom: 7px;
    margin-bottom: 0;
    min-height: 34px;
}

.form-control-static.input-lg, .form-control-static.input-sm {
    padding-left: 0;
    padding-right: 0;
}

.input-sm {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

select.input-sm {
    height: 30px;
    line-height: 30px;
}

textarea.input-sm,
select[multiple].input-sm {
    height: auto;
}

.form-group-sm .form-control {
    height: 30px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    border-radius: 3px;
}

.form-group-sm select.form-control {
    height: 30px;
    line-height: 30px;
}

.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
    height: auto;
}

.form-group-sm .form-control-static {
    height: 30px;
    min-height: 32px;
    padding: 6px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.input-lg {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33333;
    border-radius: 6px;
}

select.input-lg {
    height: 46px;
    line-height: 46px;
}

textarea.input-lg,
select[multiple].input-lg {
    height: auto;
}

.form-group-lg .form-control {
    height: 46px;
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.33333;
    border-radius: 6px;
}

.form-group-lg select.form-control {
    height: 46px;
    line-height: 46px;
}

.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
    height: auto;
}

.form-group-lg .form-control-static {
    height: 46px;
    min-height: 38px;
    padding: 11px 16px;
    font-size: 18px;
    line-height: 1.33333;
}

.has-feedback {
    position: relative;
}

.has-feedback .form-control {
    padding-right: 42.5px;
}

.form-control-feedback {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    display: block;
    width: 34px;
    height: 34px;
    line-height: 34px;
    text-align: center;
    pointer-events: none;
}

.input-lg + .form-control-feedback,
.input-group-lg + .form-control-feedback,
.form-group-lg .form-control + .form-control-feedback {
    width: 46px;
    height: 46px;
    line-height: 46px;
}

.input-sm + .form-control-feedback,
.input-group-sm + .form-control-feedback,
.form-group-sm .form-control + .form-control-feedback {
    width: 30px;
    height: 30px;
    line-height: 30px;
}

.has-success .help-block,
.has-success .control-label,
.has-success .radio,
.has-success .checkbox,
.has-success .radio-inline,
.has-success .checkbox-inline, .has-success.radio label, .has-success.checkbox label, .has-success.radio-inline label, .has-success.checkbox-inline label {
    color: #3c763d;
}

.has-success .form-control {
    border-color: #3c763d;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
    border-color: #2b542c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
    color: #3c763d;
    border-color: #3c763d;
    background-color: #dff0d8;
}

.has-success .form-control-feedback {
    color: #3c763d;
}

.has-warning .help-block,
.has-warning .control-label,
.has-warning .radio,
.has-warning .checkbox,
.has-warning .radio-inline,
.has-warning .checkbox-inline, .has-warning.radio label, .has-warning.checkbox label, .has-warning.radio-inline label, .has-warning.checkbox-inline label {
    color: #8a6d3b;
}

.has-warning .form-control {
    border-color: #8a6d3b;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
    border-color: #66512c;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
    color: #8a6d3b;
    border-color: #8a6d3b;
    background-color: #fcf8e3;
}

.has-warning .form-control-feedback {
    color: #8a6d3b;
}

.has-error .help-block,
.has-error .control-label,
.has-error .radio,
.has-error .checkbox,
.has-error .radio-inline,
.has-error .checkbox-inline, .has-error.radio label, .has-error.checkbox label, .has-error.radio-inline label, .has-error.checkbox-inline label {
    color: #a94442;
}

.has-error .form-control {
    border-color: #a94442;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
    border-color: #843534;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
    color: #a94442;
    border-color: #a94442;
    background-color: #f2dede;
}

.has-error .form-control-feedback {
    color: #a94442;
}

.has-feedback label ~ .form-control-feedback {
    top: 25px;
}

.has-feedback label.sr-only ~ .form-control-feedback {
    top: 0;
}

.help-block {
    display: block;
    margin-top: 5px;
    margin-bottom: 10px;
    color: #737373;
}

@media (min-width: 768px) {
    .form-inline .form-group {
        display: inline-block;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
    }

    .form-inline .form-control-static {
        display: inline-block;
    }

    .form-inline .input-group {
        display: inline-table;
        vertical-align: middle;
    }

    .form-inline .input-group .input-group-addon,
    .form-inline .input-group .input-group-btn,
    .form-inline .input-group .form-control {
        width: auto;
    }

    .form-inline .input-group > .form-control {
        width: 100%;
    }

    .form-inline .control-label {
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .radio,
    .form-inline .checkbox {
        display: inline-block;
        margin-top: 0;
        margin-bottom: 0;
        vertical-align: middle;
    }

    .form-inline .radio label,
    .form-inline .checkbox label {
        padding-left: 0;
    }

    .form-inline .radio input[type="radio"],
    .form-inline .checkbox input[type="checkbox"] {
        position: relative;
        margin-left: 0;
    }

    .form-inline .has-feedback .form-control-feedback {
        top: 0;
    }
}

.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 7px;
}

.form-horizontal .radio,
.form-horizontal .checkbox {
    min-height: 27px;
}

.form-horizontal .form-group {
    margin-left: -15px;
    margin-right: -15px;
}

.form-horizontal .form-group:before, .form-horizontal .form-group:after {
    content: " ";
    display: table;
}

.form-horizontal .form-group:after {
    clear: both;
}

@media (min-width: 768px) {
    .form-horizontal .control-label {
        text-align: right;
        margin-bottom: 0;
        padding-top: 7px;
    }
}

.form-horizontal .has-feedback .form-control-feedback {
    right: 15px;
}

@media (min-width: 768px) {
    .form-horizontal .form-group-lg .control-label {
        padding-top: 11px;
        font-size: 18px;
    }
}

@media (min-width: 768px) {
    .form-horizontal .form-group-sm .control-label {
        padding-top: 6px;
        font-size: 12px;
    }
}
/* Form End */

/* Dropdown Start */
.css-yk16xz-control {
    align-items: center;
    background-color: hsl(0,0%,100%);
    border: none !important;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    min-height: 38px;
    outline: 0 !important;
    position: relative;
    transition: all 100ms;
    box-sizing: border-box;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
/* Dropdown End */


/* Btn Custome CSS Start */
.MuiButton-root {
    font-size: 0.775rem !important;
    line-height: 0 !important;
    padding: 6px 4px !important;
}
.css-1pahdxg-control:hover {
    border-color: #0a0a0a !important;
}
.css-1pahdxg-control {
    border-color: #0a0a0a !important;
    outline: 0 !important;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px #0a0a0a !important;
    border: none !important;
}
.bp3-dialog {
    padding: 0;
}
.bp3-dialog-header {
    padding: 0 1em 0 1em;
}
.bp3-button.bp3-minimal:hover {
    box-shadow: none;
    background: #0a0a0a;
    text-decoration: none;
    color: #fff !important;
    border: none;
}
.bp3-button.bp3-minimal:hover .bp3-icon {
    color: #fff;
}
/* Btn Custome CSS End */

.mb_50 {margin-bottom: 50px !important;}
.mb_40 {margin-bottom: 40px !important;}
.mb_30 {margin-bottom: 30px !important;}
.mb_20 {margin-bottom: 20px !important;}
.mtb_30 {margin: 30px 0 !important;}
.ptb_30 {padding: 30px 0 !important;}

/* Home Page Switch Start */
.Switch {
    display: flex;
    justify-content: center;
}
.Switch h2 {
    padding: 0 10px;
}

.Switch .react-switch-bg {background: #000 !important;}
/* Home Page Switch End */

/* Pricing Start */
.Prepaid_Pricing {
    background: #000;
    width: 100%;
    height: auto;
    border-radius: 10px;    
}
.Pricing_header {
    background: #1C1C1C;
    text-align: center;
    height: 100px;
    border-radius: 10px 10px 0 0;
}
.Pricing_header h3 {    
    color: #fff;
    padding-top: 20px;
    text-transform: uppercase;
    font-weight: 100;
    font-family: 'Raleway', sans-serif;
    text-shadow: 0 0 0 #fff;
    width: 210px;
    display: block;
    margin: 0 auto;
    line-height: 18px;
    font-size: 0.9rem;
}
.Prepaid_Pricing .Pricing_body_circle {
    background: #000000;
    border: 1px solid #1C1C1C;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    line-height: 100px;
    padding: 0 12px;
    box-shadow: 0 5px 9px #1C1C1C;
    margin: 0 auto;
    position: relative;
    bottom: 40px;
}    
.Prepaid_Pricing .Pricing_body_circle h4 {
    color: #fff;
    text-align: center;
    margin: auto 0;
    padding: 40px 0;
    font-size: 1rem;
    font-family: sans-serif;
}
.Prepaid_Pricing .Pricing_body_list ul {
    margin: -20px 0 0 0;
    padding: 0;
}
.Prepaid_Pricing .Pricing_body_list ul li {
    list-style: none;
    display: flex;
    margin-bottom: 0.5rem;
    padding: 0 15px 0 15px;
}
.Prepaid_Pricing .Pricing_body_list ul li::before {
    content: url(/static/media/list_icon.5e99dc10.svg);
    padding-right: 10px;
}
.Prepaid_Pricing .Pricing_body_list ul li p {
    color: #f1f1f1;
    font-size: 0.8rem;
    line-height: 17px;
    margin: 0;
}
.Pricing_body {
    background: #000;
}
.Pricing_body .btn {
    height: 40px;
    width: 40%;
    background: #00010C;
    border: 1px solid #fff;
    color: #fff;
    font-weight: 100;
    letter-spacing: 1px;
    font-family: 'Raleway', sans-serif;
    margin: 17px auto;
    display: flex;
    text-transform: capitalize;
    font-size: 1rem !important;
}
.Pricing_footer {
    background: #1C1C1C;
    height: 40px;
    border-radius: 0 0 10px 10px;
}
/* Pricing End */


/* Card Detail page Start */
.Detail-sidebar {
    padding: 0 3vw;
}
.Detail-sidebar label {
    padding: 0 10px;
    font-weight: 400;
    font-family: 'Raleway';
    color: #333;
}
.bp3-control input[type=radio]:checked~.bp3-control-indicator {
    box-shadow: none;
    color: #000;
    background-color: #fff;
    border: 1px solid #fff;
    background: #fff;
    transition: .3s;
}
.bp3-control.bp3-radio input:checked~.bp3-control-indicator::before {
    background: #000;
    border-radius: 100px;
    width: 8px;
    height: 8px;
    margin: 2px auto;
    border: 5px solid #000;
    transition: .3s;
}
.d-flex {display: flex;}
.mt_10 {margin-top: 10px;}
.mt_20 {margin-top: 20px;}
.Detail-sidebar h2 {
    margin: 0;
}
.Detail-sidebar h2 strong {
    font-weight: 400 !important;
    font-family: sans-serif !important;
    font-size: 1.3rem;
    margin-bottom: 0.2rem !important;
}
.Detail-sidebar .Detail_shad {
    background: #F7F7F7;
    border: 1px solid #D9D9D9;
    border-radius: 10px;
    padding: 10px;
}
.Detail-sidebar .Detail_shad h3 {
    font-weight: 500;
    margin-bottom: 0.5rem;
}
.Detail-sidebar .Detail_shad ul {
    margin: 10px 0 0 0;
    padding: 0;
    list-style: none;
}
.Detail-sidebar .Detail_shad ul li {
    margin-bottom: 0.5rem;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
}
.Detail-sidebar .Detail_shad p {
    margin: 0;
    padding: 0 5px;
    color: #000;
}
.Detail-sidebar p {
    color: #000;
    margin-top: 20px;
}
.Detail-sidebar p strong {
    font-weight: 600;
}
.Detail-sidebar .btn {
    height: 40px;
    width: 150px;
    background: #fff;
    border: 1px solid #050505;
    color: #000;
    font-weight: 200;
    letter-spacing: 1px;
    font-family: 'Raleway', sans-serif;
    margin: 15px 0;
    display: flex;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}
.faq {
    margin-bottom: 20px;
}
.faq h1 {
    font-family: 'Raleway', sans-serif;
    text-align: center;
}
.faq .Collapsible__trigger {
    color: #000;
    font-size: 1rem;
    font-weight: 600;
    letter-spacing: 0.3px;
    cursor: pointer !important;
}
.faq ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.faq ul li {
    margin: 10px 0 0 40px;
    padding: 0;
    list-style: circle;
    color: #000;
    line-height: 16px;
}
.faq p {
    color: #000;
    margin-top: 10px;
    padding-left: 10px;
    margin-bottom: 0;
    font-size: 0.9rem;
    line-height: 23px;
}
/* Card Detail page End */

/* Checkout Start */

.balance_box .checkout {
    input {
        color: #fff;
    }
}
.checkout .css-1vv4lmi {
    &:before {
        border-bottom-color: #fff;
    }
    &:after {
        border-bottom-color: #fff;
    }
}

.checkout {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    color: #000;
    border: 2px solid #e9e9e9;
    margin-bottom: 30px;
}
.checkout h3 {
    font-weight: 600;
    margin: 0 0 15px 0;
    padding-bottom: 5px;
    border-bottom: 1px solid #cecece;
}
.checkout .checkout_head {
    margin: 20px 0 20px 0;
    font-weight: 600;
    border-bottom: 1px solid #cecece;
}
.checkout p {
    color: #000;
    font-size: 1.2rem;
    margin: 0 0 5px 0;
    padding: 0;
    line-height: 23px;
}
.checkout .btn {
    height: 40px;
    width: 200px;    
    background: #fff;
    border: 1px solid #050505;
    color: #000;
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: 20px !important;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}
/* Checkout End */

/* Voucher Stsrt */
.ggvoucher {
    background-color: #fff;
    color: #000;
}
.ggvoucher h2 {
    font-size: 1.5rem;
    font-weight: 600;
}
.ggvoucher ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.ggvoucher ul li {
    margin: 0 0 10px 0;
    padding: 0 0 5px 0;
    list-style: none;
    color: #000;
    border-bottom: 1px solid #cecece;
}
.ggvoucher ul li p {
    margin: 0;
    color: #000;
}
.ggvoucher .btn {
    height: 40px;
    width: 200px;    
    background: #fff;
    border: 1px solid #050505;
    color: #000;
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: 0;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}
.btn {
    height: 40px;
    width: 200px;    
    background: #fff;
    border: 1px solid #050505;
    color: #000;
    font-weight: 200;
    letter-spacing: 1px;
    margin-top: 20px !important;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}

.react-responsive-modal-modal {
    border-radius: 10px;
    box-shadow: 0 10px 10px -6px #000 !important;
    margin: 0;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 35%;
}
/* Voucher End */

/* Order Detail Start */
.Order_Detail {
    width: 80%;
    /* border: 1px solid #ddd; */
    padding: 10px;
    border-radius: 10px; 
    margin: 0 auto 30px auto;
    box-shadow: 0 0 10px -6px #000;
}
.Order_Detail ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.Order_Detail ul li {
    margin: 0 0 0.3rem 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;

}
.Order_Detail ul li p {
    color: #333;
    margin: 0;
    padding: 0;
}
.Order_Detail ul li p:nth-child(2) {
    color: #000;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.Order_Detail h2 {
    font-size: 1.5rem;
    font-weight: 500 !important;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
}
/* Order Detail End */

/* Transaction History Start */
.TransactionHistory_header h2 {
    margin: 0;
}
.TransactionHistory_header h2 a {
    color: #000;
    transition: 0.5s;
}
.TransactionHistory_header h2 a i {
    color: #000;
    transition: 0.5s;
}
.TransactionHistory_header h2 a:hover i {
    color: #333333;
}
.Transaction_History Table {
    width: 100%;
    height: auto;
}
.Transaction_History Table thead tr th {
    font-size: 16px;
    color: #000;
    font-weight: 600;
    text-align: left;
}
.Transaction_History Table tbody tr td {
    font-size: 15px;
    color: #000;
    font-weight: 400;
    padding: 4px 2px;
    text-align: left;
}
.Transaction_History {
    padding: 10px;
    border-radius: 10px; 
    margin: 0 auto 30px auto;
    box-shadow: 0 0 10px -6px #000;
    height: 56vh;
    overflow: hidden; 
    overflow-x: scroll;
    overflow-y: scroll;   
}
.Transaction_History ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.Transaction_History ul li {
    margin: 0 0 0.5rem 0;
    padding: 0 0 0.3rem 0;
    display: flex;
    list-style: none;
    justify-content: space-between;
    border-bottom: 1px solid #ddd;    
}
.Transaction_History ul li:nth-child(1) p {
    color: #333;
    margin: 10px 0 0 0;
    font-weight: bold;
    padding: 0;
}

.Transaction_History ul li p {
    color: #333;
    margin: 10px 0 0 0;
    padding: 0;
}

.Transaction_History ul li p:nth-child(2) {
    color: #000;
    /*font-weight: 600;*/
    letter-spacing: 0.5px;
}
.Transaction_History ul li .CLR_red {
    color: red;
}
.Transaction_History ul li .CLR_green {
    color: green;
}
.Transaction_History h2 {
    font-size: 1.5rem;
    font-weight: 500 !important;
    margin-top: 0;
    margin-bottom: 0.2rem;
}
/* Transaction History End */

/* Balance Start */
.balance_box {
    background-color: #333333;
    color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
    /* box-shadow: 0 0 10px 3px #000; */
}
.balance_box h1 {
    color: #fff;
    margin: 2rem 0 0.5rem 0;
    font-size: 1.5rem;
}
.balance_box p {
    color: #fff;
}
.balance_box .balance_innverbox h1 {
    font-size: 1.5rem;
}
.balance_btn .btn {
    height: 40px;
    width: 200px;    
    background: #333333;
    border: 1px solid #fff;
    color: #fff;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 10px 0 5px 0 !important;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}
.balance_btn .btn i {
    font-size: 1rem;
    padding-right: 5px;
    color: #fff;
}
.balance_btn.justify_space_between {
    display: flex;
    justify-content: space-around;
}
.balance_btn2 .btn {
    height: 40px;
    width: 200px;    
    background: #050505;
    border: 1px solid #858585;
    color: #fff;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 10px 0 5px 0 !important;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}
.balance_btn2 .btn i {
    font-size: 1rem;
    padding-right: 5px;
    color: #fff;
}

.css-1480iag-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid #ddd !important;
}
.css-1480iag-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid #ddd !important;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input {
    color: #b1b1b1 !important;
}
.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root {
    color: #fff !important;
}
.css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
    color: #b1b1b1 !important;
}
.css-1wt0ykv::after {
    border-bottom: 2px solid #ececec !important;
    -webkit-transform: scaleX(1) !important;
            transform: scaleX(1) !important;
}
.css-1wt0ykv::before {
    border-bottom: 1px solid #fff !important;
}
.css-1wt0ykv::before {
    border-bottom: #fff !important;
}
.css-1wt0ykv:hover:not(.Mui-disabled)::before {
    border-bottom: 2px solid #ddd !important;
}
.css-348fk2.Mui-focused {
    color: #fff !important;
}
.css-1wt0ykv {
    color: #ececec !important;
}
.css-mqt2s5 {
    color: #fff !important;
}
.css-348fk2 {
    color: #ececec !important;
}
/* Balance End */

/* Checkout Start */
.checkout_box {
    background-color: #fff;
    color: #333;
    border-radius: 10px;
    margin-bottom: 20px;
    /* box-shadow: 0 0 10px 3px #000; */
}
.balance_box .checkout_head {
    color: #fff;
    font-size: 1.5rem;
}
.balance_box h3 {
    color: #fff;
    font-size: 1.5rem;
}
.balance_box h1 {
    color: #fff;
    font-size: 1.5rem;
}
.checkout_box p {
    color: #000;
}
.checkout_box .checkout_innverbox h1 {
    font-size: 1.5rem;
}
/* Checkout End*/


/* Order Detail Start */
.Order_Detail_MDL {
    width: 100%;
    /* border: 1px solid #ddd; */
    padding: 10px;
    border-radius: 10px;
    margin: 0 auto 30px auto;
}
.Order_Detail_MDL ul {
    margin: 0;
    padding: 0;
    list-style: none;
}
.Order_Detail_MDL ul li {
    margin: 0 0 0.3rem 0;
    padding: 0;
    display: flex;
    list-style: none;
    justify-content: space-between;

}
.Order_Detail_MDL ul li p {
    color: #333;
    margin: 0;
    padding: 0;
}
.Order_Detail_MDL ul li p:nth-child(2) {
    color: #000;
    font-weight: 600;
    letter-spacing: 0.5px;
}
.Order_Detail_MDL h2 {
    font-size: 1.5rem;
    font-weight: 500 !important;
    margin-top: 1rem;
    margin-bottom: 0.8rem;
}
.odr_detail_btn .btn {
    height: 30px;
    width: 130px;
    background: #333;
    border: 1px solid #050505;
    color: #fff;
    letter-spacing: 1px;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.1rem !important;
    margin-top: 0px !important;
    margin-bottom: 5px;
    transition: 0.4s;
}
.odr_detail_btn .btn:hover {
    background: #fff;
    color: #333;
    border: 1px solid #333;
}

.react-responsive-modal-modal {
    width: 30vw;
}
@media (max-width: 992px) {
    .Order_Detail_MDL ul li p:nth-child(2) {
        text-align: right;
    }
    .react-responsive-modal-modal {
    width: 90vw;
    left: 0;
    }
    .ml-3 {
        margin-left: 20px;
    }
}
/* Order Detail End */



/* My Card Start */
.MyCard {
    background-color:#fff;
    border-radius: 20px 20px 10px 10px;
    border-bottom: 1px solid #0a0a0a;
    border-radius: 10px;
    position: relative;
    z-index: 1;
}
.MyCard:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    height: 45.2%;
    width: 100%;
    border-radius: 0 0 10px 10px;
    border-left: 0.5px solid #adadad;
    border-right: 0.5px solid #adadad;
}
.MyCard .card_detail {    
    padding: 10px 20px;
}
.MyCard .card_detail p {
    color: #050505;
    margin: 0;
    font-size: 1.2rem;
}
.MyCard .card_detail .btn {
    height: 40px;
    width: 200px;    
    background: #fff;
    border: 1px solid #050505;
    color: #050505;    
    font-weight: 200;
    letter-spacing: 1px;
    margin: 10px 0 5px 0 !important;
    text-transform: capitalize;
    border-radius: 10px;
    font-size: 1.2rem !important;
}
.css-10hburv-MuiTypography-root {
    margin: 0;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 0.9;
    letter-spacing: 0.00938em;
    display: block;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
    transition: 0.5s;
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root:hover {
    -webkit-text-decoration: none;
    text-decoration: none;
    background-color: transparent !important;
}
.css-bshv44-MuiButtonBase-root-MuiListItem-root:hover .css-10hburv-MuiTypography-root {
    color: #e96574;
}

figure {
    padding: 0;
    margin: 0;
}

@media (max-width: 1600px) {
    .textover img {
        width: 100%;
        height: auto !important;
    }
}

.textover { position: relative; } /* REQUIRED */
.textover img {
    width: 100%;
    height: 11vw;
}
.textover figcaption {
  position: absolute;
  bottom: 50px;
  left: 20px;
  font-weight: 600;
  color: #343434;
  font-family: sans-serif;
  font-size: 1rem;
}
.textover .card_type {
    position: absolute;
    top: 45px;
    left: 17px;
    font-size: 0.8rem;
    font-weight: 400;
    color: #343434;
}
.textover .card_sd {
    position: absolute;
    bottom: 30px;
    left: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #343434;
  }
  .textover .card_ed {
    position: absolute;
    bottom: 30px;
    left: 70px;
    font-size: 0.8rem;
    font-weight: 600;
    color: #343434;
  }

.MuiFormControl-root.MuiTextField-root.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    width: 100%;
}
/* My Card End */
